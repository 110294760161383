<veolia-modal #solutionShareModal *ngIf="vm$ | async as vm">
  <div veolia-modal-title>
    <h4 nz-typography>
      {{ 'Share the solution ' | translate }}
      <strong>{{ solution?.solutionName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ solution?.uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <ng-container>
      <div class="center">
        <veolia-segmented
          *ngIf="userType === 'Veolia'"
          [options]="options"
          (handleIndexChange)="handleIndexChange($event)"
          [disabled]="vm.isLoading"
          [size]="'default'" />
      </div>

      <form
        *ngIf="isOpen"
        nz-form
        [nzLayout]="'vertical'"
        [formGroup]="formOption === 0 ? externalUserForm : veoliaUserForm!"
        (ngSubmit)="submitForm()">
        <div class="form" *ngIf="formOption === 0 && solution?.type === 'SaaS'">
          <nz-form-item>
            <nz-form-label nzRequired>Contact firstname</nz-form-label>
            <nz-form-control nzErrorTip="Please input the contact firstname!">
              <input
                nz-input
                class="light-gray"
                formControlName="firstnameVendor" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired>Contact lastname</nz-form-label>
            <nz-form-control nzErrorTip="Please input the contact lastname!">
              <input
                nz-input
                class="light-gray"
                formControlName="lastnameVendor" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired>
              Contact email
              <span *ngIf="requester === 'Vendor'"
                ><veolia-icon
                  [size]="16"
                  [name]="'info'"
                  [filled]="true"
                  nz-tooltip
                  nzTooltipTitle="Domain name without restriction: {{
                    domainName
                  }}, if your contact email is matching a different domain name, the approval sharing request will be sent to the solution owner and DL&A Team"></veolia-icon
              ></span>
            </nz-form-label>
            <nz-form-control nzErrorTip="Unauthorized email domain">
              <input
                appLowercase
                nz-input
                class="light-gray"
                formControlName="emailVendor"
                type="email"
                email="true" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired>Company name</nz-form-label>
            <nz-form-control nzErrorTip="Please input the compagny name">
              <input
                nz-input
                class="light-gray"
                formControlName="companyVendor" />
            </nz-form-control>
          </nz-form-item>
          <div>
            <span
              ><strong nz-typography nzType="danger"
                >* Generic email address such as info&#64;... contact&#64;...
                and personal email address (gmail, outlook, yahoo, hotmail,
                ymail, yopmail, free...) are not authorized</strong
              ></span
            >
            <br />
            <br />
            <span *ngIf="requester === 'Veolia'"
              ><strong
                >If the account is recognised, your contact will receive a
                notification by email and will have direct access to the
                solution. If not, the account will be created and your contact
                will receive by email his credentials to log in on the
                application in order to access to the solution
              </strong></span
            >
          </div>
        </div>
        <div class="form" *ngIf="formOption === 1">
          <h4 *ngIf="solution" class="form-header">
            Share the solution (A or B option)
          </h4>

          <span *ngIf="!atLeastOneHasValue()" nz-typography nzType="danger"
            >Depending on the situation, please fill out a field</span
          >
          <div class="row" *ngIf="vm$ | async as vm">
            <nz-form-item class="half-screen">
              <nz-form-label>
                (A) Chose the veolia user you want to share this request
              </nz-form-label>
              <nz-form-control>
                <nz-select
                  nzPlaceHolder="Select an existing veolia user"
                  nzAllowClear
                  nzShowSearch
                  (nzOnSearch)="onSearch($event)"
                  class="light-gray full-width"
                  formControlName="emailVeoliaAccount">
                  <ng-container *ngFor="let u of vm.users">
                    <nz-option
                      [nzValue]="u.email"
                      [nzLabel]="u.name"></nz-option>
                  </ng-container>
                  <nz-option
                    *ngIf="vm.isLoadingUsers"
                    nzDisabled
                    nzCustomContent>
                    <span nz-icon nzType="loading" class="loading-icon"></span>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="row">
            <nz-form-item class="full-width">
              <nz-form-label
                >(B) If he is not present in the list, you can provide his email
                in field bellow</nz-form-label
              >
              <nz-form-control nzErrorTip="Please type an e-mail">
                <!-- <nz-input-group nzAddOnAfter="@veolia.com">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="emailNewUserVeolia"
                    mask="A*"
                    [patterns]="customPatterns"
                    [validation]="true"
                    [dropSpecialCharacters]="false" />
                </nz-input-group> -->
                <nz-input-group>
                  <input
                    nz-input
                    placeholder="Type an email"
                    class="light-gray"
                    formControlName="emailNewUserVeolia"
                    mask="A*"
                    [patterns]="customPatterns"
                    [validation]="true"
                    [dropSpecialCharacters]="false" />
                  <nz-select
                    formControlName="domainNewUserVeolia"
                    nzPlaceHolder="Select a veolia @domain"
                    nzAllowClear
                    nzShowSearch
                    (nzOnSearch)="onSearch($event)"
                    class="light-gray full-width"
                    style="width: 200px">
                    <nz-option
                      *ngFor="let domain of listVeoliaDomains"
                      [nzValue]="domain.domainName"
                      [nzLabel]="'@' + domain.domainName"></nz-option>
                  </nz-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="row">
            <span nz-typography nzDisabled
              >Only your organization's domain name (cannot be changed)</span
            >
          </div>
        </div>
      </form>
    </ng-container>
  </div>
  <div veolia-modal-footer>
    <veolia-button
      [size]="'large'"
      [variant]="'secondary'"
      [htmlType]="'reset'"
      (click)="reset()">
      Reset
    </veolia-button>
    <veolia-button
      (click)="submitForm()"
      *ngIf="!vm.isLoadingUsers && !vm.isLoading"
      [size]="'large'"
      [variant]="'primary'"
      [disabled]="
        formOption === 0
          ? !externalUserForm.valid
          : !atLeastOneHasValue() || !veoliaUserForm?.valid
      ">
      Share
    </veolia-button>
  </div>
</veolia-modal>
