<app-layout-page-header>
  <div class="row row-space-between row-bottom">
    <div>
      <app-layout-page-header-title
        title="Admin Home"></app-layout-page-header-title>
    </div>
    <div>
      <div class="buttons row end">
        <app-genai-settings></app-genai-settings>
        <app-documentation></app-documentation>
      </div>
    </div>
  </div>
</app-layout-page-header>

<div class="layout-body">
  <div class="layout-body-content">
    <div nz-row nzJustify="space-between">
      <!--Solutions section-->
      <nz-card
        nz-col
        [nzXXl]="8"
        [nzXl]="8"
        [nzLg]="12"
        [nzMd]="12"
        [nzSm]="12"
        [nzXs]="24"
        nzTitle="Solutions"
        [nzExtra]="solutions">
        <div class="row center spacing-v16">
          <span>
            <veolia-button
              [size]="'default'"
              [variant]="'primary'"
              [htmlType]="'button'"
              [icon]="'chevron_right'"
              [iconPosition]="'right'"
              (click)="redirectToSolutionsAdmin()">
              Solutions administration
            </veolia-button>
          </span>
        </div>
        <div class="row center">
          <!--SaaS-->
          <ul nz-list nzBordered>
            <nz-list-header class="center">
              <h3 class="center">SaaS</h3>
            </nz-list-header>
            <hr />
            <li nz-list-item nzNoFlex>
              Total:<span nz-typography
                ><kbd>{{ allSolutionsCounterSaaS }}</kbd></span
              >
            </li>
            <li nz-list-item nzNoFlex>
              Assessed:<span nz-typography
                ><kbd>{{ allSolutionsAssessedCounterSaaS }}</kbd></span
              >
            </li>
            <li nz-list-item nzNoFlex>
              Pending:<span nz-typography
                ><kbd>{{ allSolutionsPendingCounterSaaS }}</kbd></span
              >
            </li>
            <li nz-list-item nzNoFlex>
              New:<span nz-typography
                ><kbd>{{ allSolutionsNewCounterSaaS }}</kbd></span
              >
            </li>
            <li nz-list-item nzNoFlex>
              In progress:<span nz-typography
                ><kbd>{{ allSolutionsInProgressCounterSaaS }}</kbd></span
              >
            </li>
            <li nz-list-item nzNoFlex>
              Canceled:<span nz-typography
                ><kbd>{{ allSolutionsCanceledCounterSaaS }}</kbd></span
              >
            </li>
          </ul>
        </div>
      </nz-card>
      <ng-template #solutions>
        <veolia-icon [size]="24" [name]="'fact_check'"></veolia-icon>
      </ng-template>

      <!--Business units section-->
      <nz-card
        nz-col
        [nzXXl]="8"
        [nzXl]="8"
        [nzLg]="12"
        [nzMd]="12"
        [nzSm]="12"
        [nzXs]="24"
        nzTitle="Business units"
        [nzExtra]="businessUnits">
        <div class="row center spacing-v16">
          <span>
            <veolia-button
              [size]="'default'"
              [variant]="'primary'"
              [htmlType]="'button'"
              [icon]="'chevron_right'"
              [iconPosition]="'right'"
              (click)="redirectToBusinessUnitsAdmin()">
              Business units administration
            </veolia-button>
          </span>
        </div>
        <div class="row center">
          <!--SaaS-->
          <ul nz-list nzBordered>
            <nz-list-header class="center">
              <h3 class="center">Business Units</h3>
            </nz-list-header>
            <hr />
            <li nz-list-item nzNoFlex>
              Total:<span nz-typography
                ><kbd>{{ allBusinessUnitsCounter }}</kbd></span
              >
            </li>
          </ul>
        </div>
      </nz-card>
      <ng-template #businessUnits>
        <veolia-icon [size]="24" [name]="'apartment'"></veolia-icon>
      </ng-template>

      <!--Users section-->
      <nz-card
        nz-col
        [nzXXl]="8"
        [nzXl]="8"
        [nzLg]="12"
        [nzMd]="12"
        [nzSm]="12"
        [nzXs]="24"
        nzTitle="Users"
        [nzExtra]="users">
        <div class="row center spacing-v16">
          <span>
            <veolia-button
              [size]="'default'"
              [variant]="'primary'"
              [htmlType]="'button'"
              [icon]="'chevron_right'"
              [iconPosition]="'right'"
              (click)="redirectToUsersAdmin()">
              Users administration
            </veolia-button>
          </span>
        </div>

        <div class="row center">
          <!--Veolia users-->
          <ul nz-list nzBordered>
            <nz-list-header class="center">
              <h3 class="center">Veolia users</h3>
            </nz-list-header>
            <hr />
            <li nz-list-item nzNoFlex>
              Total:<span nz-typography
                ><kbd>{{ allVeoliaUsersCounter }}</kbd></span
              >
            </li>
          </ul>
          <!--Vendor users-->
          <ul nz-list nzBordered>
            <nz-list-header class="center">
              <h3 class="center">Vendor users</h3>
            </nz-list-header>
            <hr />
            <li nz-list-item nzNoFlex>
              Total:<span nz-typography
                ><kbd>{{ allVendorUsersCounter }}</kbd></span
              >
            </li>
          </ul>
        </div>
      </nz-card>
      <ng-template #users>
        <veolia-icon [size]="24" [name]="'supervisor_account'"></veolia-icon>
      </ng-template>
    </div>
  </div>
</div>
