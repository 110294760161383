<!-- <nz-divider class="spacing-v4"></nz-divider> -->
<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>
    Evaluation of {{ isChapter ? 'chapter' : 'sub chapter' }}
  </div>
  <div veolia-modal-content>
    <div *ngIf="isChapter">
      <div *ngIf="fieldId === 'DA_1'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_10' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.['DA_10_RADIO']!)"
                [label]="evaluation?.['DA_10_RADIO'] || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_11' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_11_RADIO!)"
                [label]="evaluation?.DA_11_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_12' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_12_RADIO!)"
                [label]="evaluation?.DA_12_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_13' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_13_RADIO!)"
                [label]="evaluation?.DA_13_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_14' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_14_RADIO!)"
                [label]="evaluation?.DA_14_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_150' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_150_RADIO!)"
                [label]="evaluation?.DA_150_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_15' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_15_RADIO!)"
                [label]="evaluation?.DA_15_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_16' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_16_RADIO!)"
                [label]="evaluation?.DA_16_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_2'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_21' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_21_RADIO!)"
                [label]="evaluation?.DA_21_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_22' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_22_RADIO!)"
                [label]="evaluation?.DA_22_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_23' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_23_RADIO!)"
                [label]="evaluation?.DA_23_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_24' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_24_RADIO!)"
                [label]="evaluation?.DA_24_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_25' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_25_RADIO!)"
                [label]="evaluation?.DA_25_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_26' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_26_RADIO!)"
                [label]="evaluation?.DA_24_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_27' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_27_RADIO!)"
                [label]="evaluation?.DA_24_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_3'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_31' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_31_RADIO!)"
                [label]="evaluation?.DA_31_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_32' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_32_RADIO!)"
                [label]="evaluation?.DA_32_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_4'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_41' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_41_RADIO!)"
                [label]="evaluation?.DA_41_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_42' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_42_RADIO!)"
                [label]="evaluation?.DA_42_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_43' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_43_RADIO!)"
                [label]="evaluation?.DA_43_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_44' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_44_RADIO!)"
                [label]="evaluation?.DA_44_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_45' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_45_RADIO!)"
                [label]="evaluation?.DA_45_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_46' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_46_RADIO!)"
                [label]="evaluation?.DA_46_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_47' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_47_RADIO!)"
                [label]="evaluation?.DA_47_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_5'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_51' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_51_RADIO!)"
                [label]="evaluation?.DA_51_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_52' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_52_RADIO!)"
                [label]="evaluation?.DA_52_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_53' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_53_RADIO!)"
                [label]="evaluation?.DA_53_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_54' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_54_RADIO!)"
                [label]="evaluation?.DA_54_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_6'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_61' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_61_RADIO!)"
                [label]="evaluation?.DA_61_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_62' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_62_RADIO!)"
                [label]="evaluation?.DA_62_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_63' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_63_RADIO!)"
                [label]="evaluation?.DA_63_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_64' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_64_RADIO!)"
                [label]="evaluation?.DA_64_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_65' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_65_RADIO!)"
                [label]="evaluation?.DA_65_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_7'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_71' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_71_RADIO!)"
                [label]="evaluation?.DA_71_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_72' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_72_RADIO!)"
                [label]="evaluation?.DA_72_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'DA_8'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_81' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_81_RADIO!)"
                [label]="evaluation?.DA_81_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'DA_82' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.DA_82_RADIO!)"
                [label]="evaluation?.DA_82_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'LA_1'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_10' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_10_RADIO!)"
                [label]="evaluation?.LA_10_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_11' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_11_RADIO!)"
                [label]="evaluation?.LA_11_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_111' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_111_RADIO!)"
                [label]="evaluation?.LA_111_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_112' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_112_RADIO!)"
                [label]="evaluation?.LA_112_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_113' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_113_RADIO!)"
                [label]="evaluation?.LA_113_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_114' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_114_RADIO!)"
                [label]="evaluation?.LA_114_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_115' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_115_RADIO!)"
                [label]="evaluation?.LA_115_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'LA_2'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_21_01' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_21_RADIO!)"
                [label]="evaluation?.LA_21_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_22' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_22_RADIO!)"
                [label]="evaluation?.LA_22_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_23' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_23_RADIO!)"
                [label]="evaluation?.LA_23_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_24' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_24_RADIO!)"
                [label]="evaluation?.LA_24_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_25' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_25_RADIO!)"
                [label]="evaluation?.LA_25_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_26' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_26_RADIO!)"
                [label]="evaluation?.LA_26_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'LA_3'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_31' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_31_RADIO!)"
                [label]="evaluation?.LA_31_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_32' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_32_RADIO!)"
                [label]="evaluation?.LA_32_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_33' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_33_RADIO!)"
                [label]="evaluation?.LA_33_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'LA_34' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.LA_34_RADIO!)"
                [label]="evaluation?.LA_34_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <div *ngIf="fieldId === 'AR28_GENAI'">
        <nz-list nzBordered nzSize="small">
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'AR28_GENAI_1' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.AR28_GENAI_1_RADIO!)"
                [label]="evaluation?.AR28_GENAI_1_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
          <nz-list-item>
            <span class="row row-space-between">
              <span>{{ 'AR28_GENAI_2' | translate }}</span>
              <veolia-tag
                [color]="getColor(evaluation?.AR28_GENAI_2_RADIO!)"
                [label]="evaluation?.AR28_GENAI_2_RADIO || 'DLA Assessment'" />
            </span>
          </nz-list-item>
        </nz-list>
      </div>
      <nz-divider />
    </div>
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <formly-form
        [model]="model"
        [fields]="fields"
        [form]="form"
        [options]="options"></formly-form>
    </form>
  </div>

  <div veolia-modal-footer class="row row-center">
    <div>
      <veolia-button
        [size]="'default'"
        [disabled]="
          form.invalid ||
          isVendor! ||
          !hasFullRights ||
          this.model['status'] === 'DLA Assessment'
        "
        (click)="submit()"
        [variant]="'primary'"
        >Submit</veolia-button
      >
    </div>
    <div>
      <veolia-button
        [size]="'default'"
        [disabled]="
          form.invalid ||
          isVendor! ||
          !hasFullRights ||
          this.model['status'] === 'DLA Assessment'
        "
        (click)="resetForm()"
        [variant]="'secondary'">
        Reset evaluation
      </veolia-button>
    </div>
  </div>
</veolia-modal>
