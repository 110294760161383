export const yesOrNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const yesOrNoSoonOptions = [
  ...yesOrNoOptions,
  { value: 'Soon', label: 'Soon' },
];

export const yesOrNoUnknowOptions = [
  ...yesOrNoOptions,
  { value: 'Unknow', label: 'Unknown' },
];

export const yesOrNoPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'Planned', label: 'Planned' },
];

export const yesOrNoCentralizedPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'No it is centralized', label: 'No it is centralized' },
  { value: 'Planned', label: 'Planned' },
];

export const yesOrNoNAOptions = [
  ...yesOrNoOptions,
  { value: 'N/A', label: 'N/A' },
];

export const implementationOptions = [
  { label: 'IDP initiated', value: 'IDP' },
  { label: 'SP initiated', value: 'SP' },
  { label: 'Both', value: 'Both' },
  { label: 'Unknow', value: 'Unknow' },
];

export const reportFormats = [
  { label: 'PDF', value: 'PDF' },
  { label: 'CSV', value: 'CSV' },
  { label: 'RTF', value: 'RTF' },
  { label: 'XML', value: 'XML' },
  { label: 'MS office', value: 'MS office' },
  { label: 'Others', value: 'Others' },
];

export const dataFormats = [
  { label: 'JSON', value: 'JSON' },
  { label: 'XML', value: 'XML' },
];

export const authenticationMethods = [
  { label: 'OAuth 2.0', value: 'OAuth 2.0' },
  { label: 'JWT', value: 'JWT' },
  {
    label: 'Others (Basic Auth, OpenID Connect, SAML v2, TLS)',
    value: 'Others (Basic Auth, OpenID Connect, SAML v2, TLS)',
  },
];

export const authenticationProtocols = [
  { label: 'SAML v2', value: 'SAML v2' },
  { label: 'OpenID Connect', value: 'OpenID Connect' },
];

export const monoOrMultiTenant = [
  {
    value: 'Multi-tenant',
    label: 'The SaaS is multi tenant (same tenant for all customers)',
  },
  {
    value: 'Mono tenant',
    label: 'The SaaS is mono-tenant (a single tenant for each customer)',
  },
  {
    value: 'The SaaS is both mono & multi-tenant',
    label: 'The SaaS is both mono & multi-tenant',
  },
  {
    value: 'The SaaS can be implemented in the 3 previous ways.',
    label: 'The SaaS can be implemented in the 3 previous ways.',
  },
];

export const architecturesAndRegions = [
  {
    value: 'The architecture is mono AZ (same region) by default',
    label: 'The architecture is mono AZ (same region) by default',
  },
  {
    value: 'The architecture is multi AZ (same region) by default',
    label: 'The architecture is multi AZ (same region) by default',
  },
  {
    value: 'The architecture is multi region (all actives) by default',
    label: 'The architecture is multi region (all actives) by default',
  },
  {
    value: 'There is a Disaster Recovery in an other Region by default',
    label:
      'There is a Disaster Recovery in an other Region by default or optionally',
  },
  {
    label:
      'There is a Disaster Recovery in another AZ (same region) by default or optionally',
    value:
      'There is a Disaster Recovery in another AZ (same region) by default or optionally',
  },
  {
    value: 'Architecture may vary depending on the subscribed SLA',
    label: 'Architecture may vary depending on the subscribed SLA',
  },
];

export const plannedMobileOptions = [
  { value: 'With Android mobile App', label: 'With Android mobile App' },
  { value: 'With IOS mobile App', label: 'With IOS mobile App' },
  {
    value: 'With a responsive interface',
    label: 'With a responsive interface',
  },
];

export const complianceOptions = [
  { label: 'SPF', value: 'SPF' },
  { label: 'DKIM', value: 'DKIM' },
  { label: 'DMARC', value: 'DMARC' },
  { label: 'BIMI (optional)', value: 'BIMI (optional)' },
];

export const languageOptions = [
  { label: 'French', value: 'French' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'English', value: 'English' },
  { label: 'German', value: 'German' },
  { label: 'Other', value: 'Other' },
];

export const listArt32 = [
  { label: 'Not implemented', value: 'Not implemented' },
  {
    label: 'Partially implemented or implementedon a minority of the scope',
    value: 'Partially implemented or implementedon a minority of the scope',
  },
  {
    label: 'Implemented on a majority of the scope',
    value: 'Implemented on a majority of the scope',
  },
  {
    label: 'Fully implemented on the whole scope',
    value: 'Fully implemented on the whole scope',
  },
  { label: 'Not relevant', value: 'Not relevant' },
];

export const dlaImplementation = [
  { label: 'DLA implemented', value: 'DLA implemented' },
  { label: 'DLA not implemented', value: 'DLA not implemented' },
];

export const yesOrNoPlanned2Options = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No but planned', value: 'No but planned' },
  { label: 'No not planned', value: 'No not planned' },
];

export const backupFrequencyOptions = [
  { label: '1. continuous', value: 'continuous' },
  { label: '2. <=1h', value: '<=1h' },
  { label: '3. <=12h', value: '<=12h' },
  { label: '4. daily', value: 'daily' },
  { label: '5. weekly', value: 'weekly' },
  { label: '6. other', value: 'other' },
];

export const backupStoragePlacesOptions = [
  {
    label: '1. Backups are stored with production (same AZ)',
    value: 'Backups are stored with production (same AZ)',
  },
  {
    label: '2. Backups are stored separately from production (other AZ)',
    value: 'Backups are stored separately from production (other AZ)',
  },
  {
    label: '3. Backups are stored separately from production (other DC/Region)',
    value: 'Backups are stored separately from production (other DC/Region)',
  },
];

export const backupStorageEncryptedOptions = [
  {
    label: '1. Yes, backups are encrypted',
    value: 'Yes, backups are encrypted',
  },
  {
    label: '2. No, backups are not encrypted',
    value: 'No, backups are not encrypted',
  },
  {
    label: '3. Backups are partially encrypted',
    value: 'Backups are partially encrypted',
  },
];

export const backupStorageEncryptedLevelOptions = [
  { label: '1. AES128', value: 'AES128' },
  { label: '2. AES256', value: 'AES256' },
  { label: '3. Other', value: 'Other' },
];

export const html5Options = [
  {
    label:
      '1. a website is accessible to Veolia users, partners (front, backoffice, dashboard...)',
    value:
      'a website is accessible to Veolia users, partners (front, backoffice, dashboard...)',
  },
  {
    label: '2. a thick client is available',
    value: 'a thick client is available',
  },
  {
    label: '3. a mobile app is available',
    value: 'a mobile app is available',
  },
  {
    label: '4. an API is exposed to Veolia',
    value: 'an API is exposed to Veolia',
  },
  {
    label: '5. none of the above apply',
    value: 'none of the above apply',
  },
];

export const html5PluginListOptions = [
  { label: '1. Flash', value: 'Flash' },
  { label: '2. Silverlight', value: 'Silverlight' },
  { label: '3. Java (for web browser) ', value: 'Java (for web browser)' },
  { label: '4. ActiveX', value: 'ActiveX' },
  { label: '5. Other', value: 'Other' },
];

export const browserOptions = [
  { label: '1. Chrome', value: 'Chrome' },
  { label: '2. Edge', value: 'Edge' },
  { label: '3. Firefox', value: 'Firefox' },
  { label: '4. Safari', value: 'Safari' },
  {
    label: '5. Other Major Modern Browsers',
    value: 'Other Major Modern Browsers',
  },
];

export const osOptions = [
  { label: '1. Windows', value: 'Windows' },
  { label: '2. MacOS', value: 'MacOS' },
  { label: '3. Chrome OS', value: 'Chrome OS' },
  { label: '4. Linux', value: 'Linux' },
  { label: '5. iOS', value: 'iOS' },
  { label: '6. Android', value: 'Android' },
];

export const vpnOptions = [
  { label: '1. No VPN is required', value: 'Yes' },
  { label: '2. a VPN is required', value: 'No' },
  { label: '3. a VPN will soon no longer be required', value: 'Planned' },
];

export const apiCapabilitiesOptions = [
  { label: '1. File transfer capability', value: 'File transfer capability' },
  {
    label: '2. A web interface with download and/or upload capabilities',
    value: 'A web interface with download and/or upload capabilities;',
  },
  { label: '3. REST API', value: 'REST API' },
  { label: '4. Other ways', value: 'Other ways' },
];

export const aboutFileTransferOptions = [
  { label: '1. SFTP or FTPS is available', value: 'SFTP or FTPS is available' },
  { label: '2. CFT is available', value: 'CFT is available' },
  {
    label: '3. A thick client is required',
    value: 'A thick client is required',
  },
  {
    label: '4. another protocol is available',
    value: '4. another protocol is available',
  },
];

export const restConfigOptions = [
  {
    label: '1. Our REST API are public (not authenticated)',
    value: 'Our REST API are public (not authenticated)',
  },
  {
    label: '2. Our REST API are private (authenticated)',
    value: 'Our REST API are private (authenticated)',
  },
  {
    label: '3. Our REST API are for internal use only',
    value: 'Our REST API are for internal use only',
  },
  {
    label: '4. Our API are directly reachable from the internet',
    value: 'Our API are directly reachable from the internet',
  },
];

export const crudOptions = [
  { label: '1. Create data', value: 'Create data' },
  { label: '2. Read data', value: 'Read data' },
  { label: '3. Update data', value: 'Update data' },
  { label: '4. Delete data', value: 'Delete data' },
  {
    label: "5. We don't have REST API or there are for internal use only",
    value: "We don't have REST API or there are for internal use only",
  },
];

export const restOptionsAvailability = [
  { value: 'Yes', label: 'All Data & functionalities are exposed' },
  { value: 'No', label: 'Data & functionalities are partially exposed' },
  {
    value: 'Planned',
    label: 'Partial today but a full exposure is in the roadmap',
  },
];

export const veoliaDataRetrievalOptions = [
  { value: 'Yes', label: 'Yes, it is possible at anytime' },
  { value: 'No', label: "No, it's not possible at anytime" },
  {
    value: 'Planned',
    label: "No, it's not possible at anytime but it is planned",
  },
  { value: 'NoStorage', label: "We don't store any Veolia DATA" },
];

export const agnosticProxyOptions = [
  {
    label:
      '1. Yes, a particular configuration is required if the user is behind a proxy',
    value:
      'Yes, a particular configuration is required if the user is behind a proxy',
  },
  {
    label: '2. No particular configuration is required.',
    value: 'No particular configuration is required.',
  },
];
