import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { debounceTime, Subject, Subscription, takeUntil } from 'rxjs';
import { Solution } from 'src/app/core/models/solution';
import { GenAIService } from 'src/app/core/services/genai.service';

@Component({
  selector: 'app-form-chapter',
  templateUrl: './form-chapter.component.html',
  styleUrls: ['./form-chapter.component.scss'],
})
export class FormChapterComponent implements OnInit, OnDestroy {
  @Input() solution?: Solution;
  @Input() chapterId = '';
  @Input() evaluation: any = {};
  @Input() formEvaluation: any = {};
  @Input() chapterTitle = '';
  @Input() currentChapterId?: string;
  @Input() formModel: any;
  @Input() formName = '';
  @Input() formFields?: FormlyFieldConfig[] = [];
  @Input() assessable = true;
  @Output() chapterSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() chapterEdited: EventEmitter<any> = new EventEmitter<any>();
  @Output() formEdited: EventEmitter<any> = new EventEmitter<any>();

  private chapterChangeSubject = new Subject<any>();
  private destroy$ = new Subject<void>();

  private auth: Auth = inject(Auth);

  private summarySubscription: Subscription | undefined;

  constructor(private genAIService: GenAIService) {}

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };

  fields: FormlyFieldConfig[] = [];

  ngOnInit(): void {
    if (this.formFields) {
      this.model = { ...this.buildModel() };
      this.fields = this.formFields;

      this.options.formState.disabled = !this.isVendor || this.isVendorReadOnly;

      this.chapterChangeSubject
        .pipe(debounceTime(5000), takeUntil(this.destroy$))
        .subscribe(data => {
          this.processModelChange(data);
        });

      // setTimeout(() => {
      //   this.form.valueChanges.subscribe((val: { [key: string]: any }) => {
      //     const changedFields: { [key: string]: any } = {};

      //     Object.keys(val).forEach(key => {
      //       if (val[key] !== undefined) {
      //         changedFields[key] = val[key];
      //       }
      //     });
      //     this.formEdited.emit(changedFields);
      //   });

      //   this.form.valueChanges
      //     .pipe(debounceTime(10000))
      //     .subscribe((val: { [key: string]: any }) => {
      //       const changedFields: { [key: string]: any } = {};

      //       Object.keys(val).forEach(key => {
      //         if (val[key] !== undefined) {
      //           changedFields[key] = val[key];
      //         }
      //       });
      //       this.chapterEdited.emit(changedFields);
      //     });
      // }, 5000);

      this.summarySubscription = this.genAIService.summary$.subscribe(
        summary => {
          this.model.genAI_TOMS_summary = summary;
          this.model = {
            ...this.model,
            genAI_TOMS_summary: summary,
          };
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formModel']) {
      this.updateModel();
    }
  }

  updateModel() {
    this.model = { ...this.buildModel() };
    if (this.form) {
      this.form.patchValue(this.model);
      this.form.updateValueAndValidity();
    }
  }

  get isVendor() {
    if (!this.solution?.emailVendorAuthorized) {
      return false;
    }
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    if (this.formName === 'Article 28 Form') {
      return this.solution?.emailVendorReadonlyArt28?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Article 32 Form') {
      return this.solution?.emailVendorReadonlyArt32?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Design Form') {
      return this.solution?.emailVendorReadonlyDesign?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Legal Form') {
      return this.solution?.emailVendorReadonlyLegal?.includes(
        this.auth.currentUser?.email!
      );
    } else {
      return false;
    }
  }

  buildModel() {
    const keys = this.getKeys(this.formFields);
    return Object.fromEntries(
      Object.entries(this.formModel || []).filter(([key]) => keys.includes(key))
    );
  }

  getKeys(fields: any): string[] {
    return fields.reduce((keys: any, field: { fieldGroup: any; key: any }) => {
      if (field.fieldGroup) {
        return [...keys, ...this.getKeys(field.fieldGroup)];
      } else {
        return [...keys, field.key];
      }
    }, []);
  }

  selectChapter() {
    this.chapterSelected.emit(this.chapterId);
  }

  submit() {
    this.chapterEdited.emit(this.model);
  }

  modelChanged(data: any) {
    this.chapterChangeSubject.next(data);
  }

  processModelChange(data: any) {
    const changedFields: { [key: string]: any } = {};

    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        changedFields[key] = data[key];
      }
    });

    this.chapterEdited.emit(changedFields);
    this.formEdited.emit(data);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
