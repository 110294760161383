import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { tapResponse } from '@ngrx/component-store';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { Timestamp } from '@angular/fire/firestore';
import { formatDate } from 'src/app/core/utils/date';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { GenAIService } from 'src/app/core/services/genai.service';

@Component({
  selector: 'app-genai-settings',
  templateUrl: './genai-settings.component.html',
  styleUrls: ['./genai-settings.component.scss'],
})
export class GenaiSettingsComponent implements OnInit {
  protected user?: User;
  llmList: any;
  currentLLM = '';
  lastUpdate = '';
  lastUpdateBy = '';

  genaiSettingsForm: FormGroup<{
    llmList: FormControl<string[] | null>;
    currentLLM: FormControl<string | null>;
  }> = this.fb.group({
    llmList: this.fb.control<string[] | null>(null, Validators.required),
    currentLLM: this.fb.control<string | null>(null),
  });

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private genaiService: GenAIService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    this.loadSettings();
    this.getUserData();
  }

  getUserData() {
    //ou depuis le authService (userRole) via le guetter get userRole() dans le html via userRole.admin === true etc
    this.authService.userData.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  loadSettings(): void {
    this.getGenaiSettingsData();
  }

  getGenaiSettingsData() {
    this.genaiService
      .getGenaiSettings()
      .pipe(
        tapResponse(
          data => {
            this.llmList = data.docs[0].data()['LLMlist'];
            this.currentLLM = data.docs[0].data()['currentLLM'];
            this.genaiSettingsForm.patchValue({
              llmList: this.llmList,
              currentLLM: this.currentLLM,
            });
            this.lastUpdate = data.docs[0].data()['lastUpdate'];
            this.lastUpdateBy = data.docs[0].data()['lastUpdateBy'];
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        )
      )
      .subscribe();
  }

  getLLMLabel(llm: string): string {
    return llm + (llm === this.currentLLM ? ' (current)' : '');
  }

  getFormattedDate(date: string | Timestamp) {
    return formatDate(date);
  }

  submitForm(): void {
    if (this.genaiSettingsForm.valid) {
      const updatedData = {
        currentLLM: this.genaiSettingsForm.get('currentLLM')?.value,
        lastUpdate: new Date(Date.now()),
        lastUpdateBy: this.user?.email,
      };

      this.genaiService
        .updateGenaiSettings(updatedData)
        .then(() => {
          this.veoliaMessageService.create(
            {
              title: 'LLM updated !',
              icon: 'check',
              content: '',
            },
            {
              duration: 5000,
            }
          );
          this.loadSettings();
        })
        .catch(error => {
          console.error('Error updating settings:', error);
        });
    } else {
      console.log('Form is invalid');
      Object.values(this.genaiSettingsForm.controls).forEach(control => {
        control.markAsTouched();
      });
    }
  }
}
