// genai-summary.service.ts
import { Injectable } from '@angular/core';
import {
  collection,
  Firestore,
  getDocs,
  orderBy,
  query,
} from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenAIService {
  private summarySubject = new Subject<string>();
  summary$ = this.summarySubject.asObservable();

  constructor(
    private _firestore: Firestore,
    private firestore: AngularFirestore
  ) {}

  updateSummary(summary: string) {
    this.summarySubject.next(summary);
  }

  getGenaiSettings() {
    return from(getDocs(query(collection(this._firestore, 'genaiConfig'))));
  }

  updateGenaiSettings(data: any) {
    return this.firestore
      .collection('genaiConfig')
      .doc('NbSVm8edqLjz9IyVDduh')
      .update(data);
  }
}
